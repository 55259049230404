<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 康复机构管理 </template>
      <template #input>
        <a-input v-model="keyWord" placeholder="请输入关键字" />
        <a-button class="all_boder_btn btn" type="primary" :loading="loading" @click="search()"
          >搜索</a-button
        >
        <a-button class="all_boder_btn btn" @click="onAdd()">新建</a-button>
        <a
          href="https://cos.hxclass.cn/prod/template/exam/康复机构.xlsx"
          target="_blank"
          rel="noopener noreferrer"
          class="left"
        >
          <a-button style="margin-right: 10px" class="all_boder_btn btn"
            >下载模板</a-button
          >
        </a>
        <a-upload
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
        >
          <a-button type="primary">批量导入</a-button>
        </a-upload>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="2" @change="callback">
        <a-tab-pane key="2" tab="经办机构"></a-tab-pane>
        <a-tab-pane key="1" tab="定点服务机构"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table"
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="edit(row)">编辑</a>
            <span>|</span>
            <a @click="deleteData(row)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      :title="(state == 1 ? '新建' : '编辑') + '机构'"
      :footer="null"
    >
      <a-form-model
        ref="ruleForm"
        :model="formObJ"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-model-item label="机构名称" prop="name">
          <a-input placeholder="请输入机构名称" v-model="formObJ.name" />
        </a-form-model-item>
        <a-row class="rows">
          <a-col span="5" class="left">机构地区</a-col>
          <a-col span="19" class="right">
            <Province
              style="width: 236px"
              @regionCity="FunProvince"
              :value="addressValue"
            ></Province>
          </a-col>
        </a-row>
        <a-form-model-item label="详细地址" prop="address">
          <a-textarea
            v-model="formObJ.address"
            placeholder="请输入详细地址"
            :auto-size="{ minRows: 2, maxRows: 6 }"
          />
        </a-form-model-item>

        <a-form-model-item label="联系电话" prop="mobile">
          <a-input v-model="formObJ.mobile" placeholder="请输入联系电话" />
        </a-form-model-item>
        <a-form-model-item label="服务项目" prop="item">
          <a-input v-model="formObJ.item" placeholder="请输入服务项目" />
        </a-form-model-item>
        <a-form-model-item v-if="formObJ.type == 2" label="经办人" prop="operator">
          <a-input v-model="formObJ.operator" placeholder="请输入经办人" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" :loading="PreventLoad" @click="onSubmit"> 提交 </a-button>
          <a-button style="margin-left: 10px" @click="visible = false">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
import Province from "@/components/provinceCity";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
import HeaderBox from "@/components/HeaderBox.vue";
// 经办机构
const columns1 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "地址",
    align: "center",
    dataIndex: "localName",
  },
  {
    title: "联系电话",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "服务项目",
    align: "center",
    dataIndex: "item",
  },
  {
    title: "经办人",
    align: "center",
    dataIndex: "operator",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
// 定点服务机构
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "地址",
    align: "center",
    dataIndex: "localName",
  },
  {
    title: "联系电话",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "服务项目",
    align: "center",
    dataIndex: "item",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "地址",
    align: "center",
    dataIndex: "localName",
  },
  {
    title: "联系电话",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "服务项目",
    align: "center",
    dataIndex: "item",
  },
  {
    title: "经办人",
    align: "center",
    dataIndex: "operator",
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox, Province, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      state: 1, // 1新建，2编辑
      visible: false,
      columns: columns1,
      columns1, // 表头
      columns2,
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      tabIndex: "1",
      addressValue: [], //省市区编码
      keyWord: null, //关键字
      formObJ: {
        address: "", //详细地址
        province: null, //省
        city: null, //	市编码
        area: null, //	区编码
        type: 2, //	机构类型：1.定点机构 2.经办机构
        item: "", //	服务项目
        mobile: "", //	联系电话
        name: "", //机构名称
        operator: "", //经办人
      }, //需要传输的数据
      // 校验
      rules: {
        name: [
          { required: true, message: "请输入机构名称", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
        item: [
          { required: true, message: "请输入服务项目", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入联系电话", trigger: "change" },
        ],
        operator: [
          { required: true, message: "请输入经办人", trigger: "change" },
        ],
      },
      // 弹框
      columnsss,
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
      PreventLoad: false
    };
  },
  // 事件处理器
  methods: {
    modelhandleCancel() {
      this.importVisible = false;
    },

    // 提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.formObJ.city) {
            this.$message.warning("请正确填写地址");
            return;
          }
          this.handleOk();
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.getData();
    },
    // 新建
    onAdd() {
      let type = this.formObJ.type;
      for (let key in this.formObJ) {
        this.formObJ[key] = null;
      }
      this.formObJ.type = type;
      this.addressValue = [];
      this.state = 1;
      this.label = "";
      this.visible = true;
      this.workUnitId = null;
    },
    // tab切换
    callback(e) {
      this.columns = e == 2 ? this.columns1 : this.columns2;
      this.formObJ.type = e;
      
      this.pageNumber = 1;
      this.getData();
    },
    // 获取省市区编码
    FunProvince(data) {
      // console.log(data);
      this.$set(this, "addressValue", []);
      this.$set(this, "addressValue", data);

      switch (data.length) {
        case 1:
          this.formObJ.province = data[0];
          break;
        case 2:
          this.formObJ.city = data[1];
          break;
        case 3:
          this.formObJ.area = data[2];
          break;

        default:
          break;
      }
    },
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },
    // 获取数据
    getData() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/institution/list",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          type: this.formObJ.type,
          keyWord: this.keyWord,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    edit(e) {
      // console.log(e);
      this.state = 2;
      this.visible = true;
      let arr = [e.province, e.city, e.area];
      let ayy = [];
      for (let i = 0; i < arr.length; i++) {
        // for (let j = 0; j < i; j++) {
        ayy[i] = arr[i];
        this.FunProvince(ayy);
        // }
      }
      this.FunProvince(arr);

      this.formObJ = e;
    },
    // 确定新建
    handleOk() {
      this.PreventLoad = true;
      let url = "";
      let method = "";
      // 新增
      if (this.state == 1) {
        url = "/hxclass-management/institution/insert";
        method = "POST";
      }
      // 编辑
      else if (this.state == 2) {
        url = "/hxclass-management/institution/update";
        method = "PUT";
      }
      this.$ajax({
        url,
        method,
        params: this.formObJ,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.label = "";
          this.getData();
          if (this.state == 1) {
            this.$message.success("新增标签成功");
          } else {
            this.$message.success("编辑标签成功");
          }
        }
        this.PreventLoad = false;
      });
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/institution/import",
        method: "PUT",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.success
          this.failureList = res.data.fail
          this.importVisible = true
        } else {
          this.$message.error(res.message);
        }
        // this.getData();
      });
    },

    // // 下载错误excel
    // downloadExcel() {
    //   if (!this.failureList.length) {
    //     return;
    //   }
    //   window.open(
    //     this.$config.target +
    //       "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
    //       encodeURIComponent(JSON.stringify(this.failureList)) +
    //       "&type=2"
    //   );
    // },

    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      // console.log(this.failureList)
      // return
      this.$ajax({
        url: "/hxclass-management/institution/export/institution/wrong",
        method: "post",
        headers: [{type: 'file'}],
        params: {
          errorJson: JSON.stringify(this.failureList)
        },
        responseType: 'blob'
      }).then((res) => {
        this.blobDownload(res,'错误文件')
      });
    },

    // blob文件转excel
    blobDownload(data,name){
      let m=this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content],{type:"application/vnd.ms-excel;charset=utf-8"});
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name+".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 导入正确数据
    importSuccessList() {
      this.$ajax({
        url: "/hxclass-management/institution/import/insert",
        method: "POST",
        params: this.successList,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 删除标签
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确定删除该机构吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/institution/delete?id=" + item.id,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getData();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  margin-top: 16px;
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.table {
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.rows {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input {
      flex: 1;
    }
    a {
      margin-left: 8px;
    }
  }
}
.radio {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .radioItem {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    img {
      max-width: 100px;
      max-height: 120px;
      margin-bottom: 10px;
    }
  }
}
</style>
